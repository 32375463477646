<template>
  <div class="group-item overflow-hidden" style="max-width: 120px">
    <!-- Avatar -->
    <router-link :to="$utils.groupRoute(group)">
      <v-img
        :src="$utils.userImage(group, '120x140')"
        width="120"
        height="140"
        :alt="group.name"
        class="rounded"
      />
    </router-link>

    <!-- Name -->
    <router-link
      class="name d-block btn-basic mt-3 font-weight-medium"
      :to="$utils.groupRoute(group)"
    >
      {{ group.name }}
    </router-link>

    <!-- Members number -->
    <small class="text--secondary font-size-12 font-weight-medium">
      {{ $tc("groups.nMembers", group.joined_users_count) }}
    </small>

    <!-- Join button -->
    <GroupJoinButton
      v-if="joinButton"
      :group="group"
      color="secondary"
      block
      x-small
      class="mt-1"
    />
  </div>
</template>

<script>
import GroupJoinButton from "./GroupJoinButton.vue";

export default {
  components: { GroupJoinButton },

  props: {
    group: Object,
    joinButton: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
