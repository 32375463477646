<template>
  <column-layout class="page" no-right>
    <!-- Left -->
    <template v-slot:left>
      <Menu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget card pa mt-9">
        <!-- Header -->
        <div class="tab-buttons">
          <v-btn
            :to="{ name: 'app.groups' }"
            :color="!isSuggested ? 'primary' : 'secondary'"
            rounded
            width="127"
            class="tab-button"
          >
            {{ $t("common.groups") }}
          </v-btn>

          <v-btn
            :to="{ name: 'app.groups.suggested' }"
            :color="isSuggested ? 'primary' : 'secondary'"
            rounded
            class="tab-button"
          >
            {{ $t("feed.suggested") }}
          </v-btn>

          <v-spacer />

          <template v-if="!isSuggested">
            <!-- Create modal -->
            <GroupFormModal />

            <SearchInput
              v-model="searchInput"
              class="rounded-lg"
              style="max-width: 250px"
              @search="
                ($e) => {
                  resetData();
                  fetchData();
                }
              "
            />
          </template>
        </div>

        <v-divider class="my-5" />

        <!-- Spinner -->
        <spinner v-if="!meta.current_page && $loading(action)" center />

        <!-- Alerts -->
        <v-alert v-else-if="!groups.length" type="info" text class="mb-0">
          {{
            searchInput
              ? $t("common.noResultsLong")
              : isSuggested
              ? $t("common.noDataFound")
              : $t("groups.noGroups")
          }}
        </v-alert>

        <!-- Items -->
        <div class="items d-flex flex-wrap" style="gap: 20px">
          <GroupItem
            v-for="item in groups"
            :key="item.id"
            :group="item"
            class="mb-5"
            :joinButton="isSuggested"
          />
        </div>

        <!-- Load more -->
        <div v-if="$utils.canLoadMore(meta)" class="text-center w-100">
          <v-btn
            color="primary"
            small
            rounded
            @click="fetchData"
            :loading="!!$loading(action)"
          >
            {{ $t("common.loadMore") }}
          </v-btn>
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import Menu from "@/components/app/Menu.vue";
import GroupFormModal from "@/components/app/groups/GroupFormModal.vue";
import GroupItem from "@/components/app/groups/GroupItem.vue";
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  metaInfo() {
    return { title: "Groups" };
  },

  components: { Menu, GroupFormModal, GroupItem, SearchInput },

  props: {
    type: String,
  },

  data: () => ({
    searchInput: "",
    groups: [],
    meta: {
      current_page: 0,
    },
  }),

  computed: {
    tab() {
      return this.$route.meta.type;
    },

    isSuggested() {
      return this.tab === "suggested";
    },

    action() {
      return this.isSuggested
        ? "groups/fetchSuggestions"
        : "groups/fetchJoined";
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      if (this.$loading(this.action)) {
        return;
      }

      const params = {
        page: this.meta.current_page + 1,
        per_page: 24,
      };

      if (this.searchInput) {
        params["search[name]"] = this.searchInput;
      }

      this.$store
        .dispatch(this.action, params)
        .then((res) => {
          this.meta = res.meta || { current_page: 0 };
          this.groups.push(...res.data);
        })
        .catch(() => {
          this.$toast.error("Error while getting groups.");
        });
    },

    resetData() {
      this.groups = [];
      this.meta = {
        current_page: 0,
      };
    },
  },
};
</script>

<style lang="scss"></style>
